::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(245, 245, 245, 0.1);
  border-radius: 5px;
}

::-webkit-scrollbar {
  display: none;
  width: 5px;
  background-color: rgba(245, 245, 245, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(245, 245, 245, 0.1);
  background-image: -webkit-gradient(linear,
          40% 0%,
          75% 84%,
          from(#951556),
          to(#35013f),
          color-stop(.6, #35013f))
}
