.container-home {
  background: url("background_oria.png") no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 100vh;

  .container-logo {
    justify-content: center;
    align-items: center;

    .logo {
      width: 200px;
      height: 200px;
      //background-color: $berry;
      margin-top: 7em;
      //@include border_radius($size-border-radius, false);

      p {
        width: 100px;
        height: 100px;
        margin: auto;
      }
    }
  }

  .flex-container-custom {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;

    height: 100%;

    .flex-item {
      flex-grow: 1;
      width: 33%;
      height: 100%;
      margin: 0;

      // Flexbox styles
      display: flex;
      flex-direction: column;
      justify-content: stretch;

      // Other styles
      font-size: 5vmin;
      font-weight: bold;
      text-align: center;

      header,
      footer {
        // Flexbox styles
        flex: 0 0 auto;

        // Other styles
        padding: 2vmin;
        background-color: #444;
        color: #eee;
      }

      main {
        // Flexbox styles
        padding: 2vmin;

        // Other styles
        flex: 1 0 auto;
      }

      footer {
        background-color: #666;
      }
    }
  }

  .ic-circle {
    background: #E9B5D2;
  }
}
