@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bitter:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
.row {
  width: auto; }

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(245, 245, 245, 0.1);
  border-radius: 5px; }

::-webkit-scrollbar {
  display: none;
  width: 5px;
  background-color: rgba(245, 245, 245, 0.1); }

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(245, 245, 245, 0.1);
  background-image: -webkit-gradient(linear, 40% 0%, 75% 84%, from(#951556), to(#35013f), color-stop(0.6, #35013f)); }

.messagin .grid-container {
  margin: 0;
  height: 100%;
  overflow: hidden;
  display: grid;
  position: absolute;
  grid-template-columns: 1fr;
  grid-template-rows: 0fr 0.79fr 0.139fr;
  grid-gap: 0px 0px;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  grid-template-areas: "header-messaging" "content-messaging" "footer-messaging";
  bottom: 0;
  top: 56px;
  width: 100%; }

.messagin .header-messaging {
  grid-area: header-messaging; }

.messagin .content-messaging {
  grid-area: content-messaging;
  overflow: scroll; }

.messagin .footer-messaging {
  grid-area: footer-messaging;
  position: relative; }

.container-carousel {
  overflow: scroll;
  width: calc(398px * 4); }

.container-home {
  background: url(/static/media/background_oria.9a8680b8.png) no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 100vh; }
  .container-home .container-logo {
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .container-home .container-logo .logo {
      width: 200px;
      height: 200px;
      margin-top: 7em; }
      .container-home .container-logo .logo p {
        width: 100px;
        height: 100px;
        margin: auto; }
  .container-home .flex-container-custom {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    height: 100%; }
    .container-home .flex-container-custom .flex-item {
      -webkit-flex-grow: 1;
              flex-grow: 1;
      width: 33%;
      height: 100%;
      margin: 0;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: stretch;
              justify-content: stretch;
      font-size: 5vmin;
      font-weight: bold;
      text-align: center; }
      .container-home .flex-container-custom .flex-item header,
      .container-home .flex-container-custom .flex-item footer {
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
        padding: 2vmin;
        background-color: #444;
        color: #eee; }
      .container-home .flex-container-custom .flex-item main {
        padding: 2vmin;
        -webkit-flex: 1 0 auto;
                flex: 1 0 auto; }
      .container-home .flex-container-custom .flex-item footer {
        background-color: #666; }
  .container-home .ic-circle {
    background: #E9B5D2; }

input {
  border-radius: 25px;
  border: solid 2px #ffffff;
  width: 100px;
  height: 52px;
  margin-top: 20px;
  background-color: transparent;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-right: 10px;
  margin-bottom: 17px; }
  input:hover {
    background-color: #ffffff;
    color: #951556; }
  input .hide {
    display: none; }

.ant-spin-text {
  color: #ffffff; }

.ant-spin-dot-item {
  background-color: white; }

html,
body {
  min-height: 100%; }

body {
  background-image: radial-gradient(circle at 29% 24%, #951556, #35013f 63%); }

.grid-container-rectangle {
  display: grid;
  grid-template-columns: 1fr 3.5fr 1fr;
  grid-template-rows: 0fr 1fr;
  grid-gap: 0px 0px;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  grid-template-areas: ". header ." "side-right content .";
  height: 86vh;
  position: relative; }

.side-right {
  grid-area: side-right;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: flex-end;
          align-items: flex-end; }

.grid-container-footer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  grid-template-areas: "footer"; }

.content {
  grid-area: content;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  overflow: scroll; }

.generatecssdotcome_circle-right {
  width: 104px;
  height: 104px;
  border-radius: 104px;
  border-style: solid;
  border-color: #E9B5D2;
  line-height: 208px;
  text-align: center;
  overflow: hidden;
  position: absolute;
  bottom: 232px;
  right: 108px; }

.generatecssdotcome_circle {
  width: 76px;
  height: 76px;
  background-color: #951556;
  border-radius: 100px;
  line-height: 200px; }

.generatecssdotcome_circle-up-left {
  width: 40px;
  height: 40px;
  background-color: #e9b5d2;
  border-radius: 100px; }

.generatecssdotcome_circle-up-right {
  width: 40px;
  height: 40px;
  background-color: #e9b5d2;
  border-radius: 100px;
  position: absolute;
  right: 150px;
  top: 47px; }

.illu-nana {
  position: absolute;
  bottom: 0;
  right: 199px; }

.ic-circle {
  margin: 71px 84px 38px 0;
  background-color: #e9b5d2; }

.ic-circle {
  margin: 86px 12px 30px 36px;
  background-color: #951556; }

*, *:active, *:focus, *:hover {
  outline: none; }

.button_parent :first-child {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: hideButtonYes;
          animation-name: hideButtonYes;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.header-rectangle {
  grid-area: header;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  height: 181px; }

.footer {
  grid-area: footer;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  position: relative; }

.slick-slide > div {
  margin: 0 10px; }

.slick-dots li button:before {
  color: #ffffff; }

.slick-active li button:before {
  color: aqua; }

.slick-list {
  margin: 0 -10px; }

.container {
  width: auto; }
  .container img {
    margin-bottom: 17px; }

.move_logo_header {
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-name: moveLogo;
          animation-name: moveLogo;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.apply_animation_form_signup {
  opacity: 0;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-name: oria_animation;
          animation-name: oria_animation;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.flex-section {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 0; }

.flex-col-scroll {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  overflow: auto;
  min-height: 100%; }

.flex-no-shrink {
  -webkit-flex-shrink: 0;
          flex-shrink: 0; }

.animate-delay-3 {
  -webkit-animation-delay: 900ms;
          animation-delay: 900ms; }

.animate-delay-6 {
  -webkit-animation-delay: 3s;
          animation-delay: 3s; }

.button {
  width: 120px;
  text-align: center; }
  .button:hover {
    background-color: #ffffff;
    color: #951556; }
  .button_non {
    opacity: 1 !important;
    -webkit-animation-duration: 100ms !important;
            animation-duration: 100ms !important;
    -webkit-animation-name: button_no_animation !important;
            animation-name: button_no_animation !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    -webkit-animation-fill-mode: forwards !important;
            animation-fill-mode: forwards !important;
    -webkit-animation-delay: 0s !important;
            animation-delay: 0s !important;
    -webkit-animation-delay: initial !important;
            animation-delay: initial !important; }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
      .button_non {
        /* CSS rules here */
        display: none; } }
    @media only screen and (max-width: 600px) {
      .button_non {
        display: none; } }

.oria-enter {
  opacity: 0;
  -webkit-transform: translateX(100px);
          transform: translateX(100px); }

.oria-appear {
  opacity: 0;
  -webkit-transform: translateX(100px);
          transform: translateX(100px); }

.oria-enter-active {
  opacity: 1;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms; }

.oria-appear-active {
  opacity: 1;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  transition: opacity 500ms, -webkit-transform 500ms;
  transition: opacity 500ms, transform 500ms;
  transition: opacity 500ms, transform 500ms, -webkit-transform 500ms; }

.oria-exit {
  opacity: 1; }

.oria-exit-active {
  opacity: 0;
  transition: opacity 200ms; }

.alert-enter {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9); }

.alert-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms; }

.alert-exit {
  opacity: 1; }

.alert-exit-active {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  transition: opacity 300ms, -webkit-transform 300ms;
  transition: opacity 300ms, transform 300ms;
  transition: opacity 300ms, transform 300ms, -webkit-transform 300ms; }

.isStudent {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  display: -webkit-flex;
  display: flex; }
  .isStudent p {
    font-family: Roboto, serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: 0.36px;
    color: #210227;
    padding: 14px;
    display: inline-block;
    border-radius: 8px;
    border: solid 1px #210227;
    background-color: #ffffff;
    white-space: pre-wrap;
    margin: 6px 6px 26px; }

@-webkit-keyframes button_no_animation {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px); }
  50% {
    background-color: #ffffff; }
  100% {
    background-color: #ffffff;
    color: #951556;
    -webkit-transform: translateX(600px);
            transform: translateX(600px); } }

@keyframes button_no_animation {
  0% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px); }
  50% {
    background-color: #ffffff; }
  100% {
    background-color: #ffffff;
    color: #951556;
    -webkit-transform: translateX(600px);
            transform: translateX(600px); } }

@-webkit-keyframes hideButtonYes {
  0% {
    opacity: 1; }
  22% {
    opacity: 0.5; }
  31% {
    opacity: 0.5; }
  34% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes hideButtonYes {
  0% {
    opacity: 1; }
  22% {
    opacity: 0.5; }
  31% {
    opacity: 0.5; }
  34% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes mouveLogo {
  0% { }
  16% {
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%);
    height: 120px; }
  50% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
    height: 100px; }
  100% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
    height: 100px; } }

@keyframes mouveLogo {
  0% { }
  16% {
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%);
    height: 120px; }
  50% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
    height: 100px; }
  100% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
    height: 100px; } }

@-webkit-keyframes mouveWelcome {
  0% { }
  16% {
    -webkit-transform: translateX(-130%);
            transform: translateX(-130%);
    height: 120px; }
  50% {
    -webkit-transform: translateX(-170%);
            transform: translateX(-170%);
    height: 100px; }
  100% {
    -webkit-transform: translateX(-170%);
            transform: translateX(-170%);
    height: 100px; } }

@keyframes mouveWelcome {
  0% { }
  16% {
    -webkit-transform: translateX(-130%);
            transform: translateX(-130%);
    height: 120px; }
  50% {
    -webkit-transform: translateX(-170%);
            transform: translateX(-170%);
    height: 100px; }
  100% {
    -webkit-transform: translateX(-170%);
            transform: translateX(-170%);
    height: 100px; } }

.logoOriaAnimation {
  -webkit-animation: mouveLogo 1s forwards 0s linear;
          animation: mouveLogo 1s forwards 0s linear; }

.welcomeAnimation {
  -webkit-animation: mouveWelcome 1s forwards 0s linear;
          animation: mouveWelcome 1s forwards 0s linear; }

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .imgLogo {
    width: 81px; } }

.ant-carousel .slick-slide {
  width: 100%; }
  .ant-carousel .slick-slide img {
    display: inline-block; }

.ant-carousel .slick-slider .slick-list {
  padding-bottom: 33px; }

.grid-container {
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 7.5fr 1fr;
  grid-gap: 0px 0%;
  grid-gap: 0px 0%;
  gap: 0px 0%;
  grid-template-areas: "header" "body" "footer"; }
  @media only screen and (width: 280px) {
    .grid-container {
      height: 100vh;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 1fr 7.5fr 1fr;
      grid-gap: 0px 0%;
      grid-gap: 0px 0%;
      gap: 0px 0%;
      grid-template-areas: "header" "body" "footer"; } }
  @media only screen and (min-width: 360px) and (max-width: 767px) {
    .grid-container {
      height: 93vh;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: -webkit-min-content 7.5fr 1fr;
      grid-template-rows: min-content 7.5fr 1fr;
      grid-gap: 0px 0%;
      grid-gap: 0px 0%;
      gap: 0px 0%;
      grid-template-areas: "header" "body" "footer"; } }
  .grid-container .header {
    grid-area: header;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
      .grid-container .header {
        grid-area: header;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center; } }
    @media only screen and (width: 360px) and (height: 640px) {
      .grid-container .header {
        grid-area: header;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center; } }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
      .grid-container .header img {
        height: 71px; } }
  .grid-container .body {
    grid-area: body;
    overflow-y: scroll; }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
      .grid-container .body {
        height: auto; } }
  .grid-container .footer {
    grid-area: footer; }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    .grid-container {
      grid-template-rows: -webkit-min-content 7.5fr 1fr;
      grid-template-rows: min-content 7.5fr 1fr;
      height: 87vh; } }

@media only screen and (min-width: 360px) and (max-width: 767px) {
  .grid-container-browser {
    height: 87vh;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 7.5fr 1fr;
    grid-gap: 0px 0%;
    grid-gap: 0px 0%;
    gap: 0px 0%;
    grid-template-areas: "header" "body" "footer"; } }

.grid-container-ios {
  height: 88vh !important;
  grid-template-rows: -webkit-min-content 7.5fr 1fr;
  grid-template-rows: min-content 7.5fr 1fr;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 0px 0%;
  grid-gap: 0px 0%;
  gap: 0px 0%;
  grid-template-areas: "header" "body" "footer"; }
  .grid-container-ios img {
    height: 71px; }

