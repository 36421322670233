.messagin {
  .grid-container {
    margin: 0;
    height: 100%;
    overflow: hidden;
    display: grid;
    position: absolute;
    grid-template-columns: 1fr;
    grid-template-rows: 0fr 0.79fr 0.139fr;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    grid-template-areas:
        "header-messaging"
        "content-messaging"
        "footer-messaging";
    bottom: 0;
    top: 56px;
    width: 100%;
  }

  .header-messaging {
    grid-area: header-messaging;
  }

  .content-messaging {
    grid-area: content-messaging;
    overflow: scroll;
  }

  .footer-messaging {
    grid-area: footer-messaging;
    position: relative;
  }
}

