@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

.ant-spin-text {
  color: #ffffff;
}

.ant-spin-dot-item {
  background-color: white;
}

html,
body {
  min-height: 100%;

}
body {
  background-image: radial-gradient(circle at 29% 24%, #951556, #35013f 63%);
}

.grid-container-rectangle {
  display: grid;
  grid-template-columns: 1fr 3.5fr 1fr;
  grid-template-rows: 0fr 1fr;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  grid-template-areas:
        ". header ."
         "side-right content .";
  height: 86vh;
  position: relative;
}
.side-right {
  grid-area: side-right;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: flex-end;
}

.grid-container-footer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr;
  gap: 0px 0px;
  grid-template-areas:
    "footer";
}

.content {
  grid-area: content;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: scroll;
}


.generatecssdotcome_circle-right {
  width: 104px;
  height: 104px;
  border-radius: 104px;
  border-style: solid;
  border-color: #E9B5D2;
  line-height: 208px;
  text-align: center;
  overflow: hidden;
  position: absolute;
  bottom: 232px;
  right: 108px;
}



.generatecssdotcome_circle {
  width: 76px;
  height: 76px;
  background-color: #951556;
  border-radius: 100px;
  line-height: 200px;
}
.generatecssdotcome_circle-up-left {
  width: 40px;
  height: 40px;
  background-color: #e9b5d2;
  border-radius: 100px;
}

.generatecssdotcome_circle-up-right {
  width: 40px;
  height: 40px;
  background-color: #e9b5d2;
  border-radius: 100px;
  position: absolute;
  right: 150px;
  top: 47px;
}

.illu-nana {
  position: absolute;
  bottom: 0;
  right: 199px;
}

.ic-circle {
  margin: 71px 84px 38px 0;
  background-color: #e9b5d2;
}


.ic-circle {
  margin: 86px 12px 30px 36px;
  background-color: #951556;
}


*, *:active, *:focus, *:hover {
  outline: none;
}


.button_parent {
  :first-child {
    animation-duration: 1s;
    animation-name: hideButtonYes;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}


.header-rectangle {
  grid-area: header;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 181px;
}



.footer {
  grid-area: footer;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  position: relative;
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-dots li button:before {
  color: #ffffff;
}

.slick-active li button:before{
  color: aqua;
}

.slick-list {
  margin: 0 -10px;
}

.container {
  width: auto;

  img {
    margin-bottom: 17px;
  }

  //.text-oria-p {
  //  p {
  //    font-family: Roboto, serif;
  //    font-size: 15px;
  //    //font-weight: 700;
  //    font-stretch: normal;
  //    font-style: normal;
  //    line-height: 1.44;
  //    letter-spacing: 0.36px;
  //    color: #ffffff;
  //    padding: 14px;
  //    display: inline-block;
  //    border-radius: 8px;
  //    border: solid 1px rgb(33, 2, 39);
  //    background-color: rgb(33, 2, 39);
  //    white-space: pre-wrap;
  //    margin: 6px 6px 26px;
  //
  //  }
  //}
}

//end container

.move_logo_header {
  animation-duration: 5s;
  animation-name: moveLogo;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}


.apply_animation_form_signup {
  opacity: 0;
  animation-duration: 5s;
  animation-name: oria_animation;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.flex-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.flex-col-scroll {
  flex-grow: 1;
  overflow: auto;
  min-height: 100%
}

.flex-no-shrink {
  flex-shrink: 0;
}

.animate-delay-3 {
  animation-delay: 900ms;
}

.animate-delay-6 {
  animation-delay: 3s;
}

.button {
  width: 120px;
  text-align: center;

  &:hover {
    background-color: #ffffff;
    color: #951556;
  }


  &_non {
    opacity: 1 !important;
    animation-duration: 100ms !important;
    animation-name: button_no_animation !important;
    animation-iteration-count: 1 !important;
    animation-fill-mode: forwards !important;
    animation-delay: initial !important;
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
      /* CSS rules here */
      display: none;
    }
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
}

.oria-enter {
  opacity: 0;
  transform: translateX(100px);
}

.oria-appear {
  opacity: 0;
  transform: translateX(100px);
}

.oria-enter-active {
  opacity: 1;
  transform: rotate(0);
  transition: opacity 300ms, transform 300ms;
}

.oria-appear-active {
  opacity: 1;
  transform: rotate(0);
  transition: opacity 500ms, transform 500ms;
}

.oria-exit {
  opacity: 1;
}

.oria-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.isStudent {
  animation-duration: 1s;
  flex-direction: row-reverse;
  display: flex;

  p {
    font-family: Roboto, serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: 0.36px;
    color: #210227;
    padding: 14px;
    display: inline-block;
    border-radius: 8px;
    border: solid 1px #210227;
    background-color: #ffffff;
    white-space: pre-wrap;
    margin: 6px 6px 26px;
  }
}


@keyframes button_no_animation {
  0% {
    transform: translateX(100px);
  }
  50% {
    background-color: #ffffff;
  }
  100% {
    background-color: #ffffff;
    color: #951556;
    transform: translateX(600px);
  }


}

@keyframes hideButtonYes {
  0% {
    opacity: 1;
  }
  22% {
    opacity: 0.5;
  }
  31% {
    opacity: 0.5;
  }
  34% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }

}

@keyframes mouveLogo{
  0%{
  }
  16%{
    transform: translateX(-150%);
    height: 120px;
  }
  50%{
    transform: translateX(-200%);
    height: 100px;
  }
  100%{
    transform: translateX(-200%);
    height: 100px;
  }
}

@keyframes mouveWelcome{
  0%{
  }
  16%{
    transform: translateX(-130%);
    height: 120px;
  }
  50%{
    transform: translateX(-170%);
    height: 100px;
  }
  100%{
    transform: translateX(-170%);
    height: 100px;
  }
}

.logoOriaAnimation{
  animation: mouveLogo 1s forwards 0s linear;
}

.welcomeAnimation{
  animation: mouveWelcome 1s forwards 0s linear;
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .imgLogo {
    width: 81px;
  }
}


