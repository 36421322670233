input{
  border-radius: 25px;
  border: solid 2px #ffffff;
  width: 100px;
  height: 52px;
  margin-top: 20px;
  background-color: transparent;
  color: rgb(255, 255, 255);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-right: 10px ;
  margin-bottom: 17px;
  &:hover {
    background-color: #ffffff;
    color: #951556;
  }

  .hide {
    display: none;
  }



}
