@mixin border_radius($size, $true) {
  @if $true == true {
    -webkit-border-radius: $size;
    -moz-border-radius: $size;
    border-radius: $size;
  }
}

@mixin display_flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.row {
  width: auto;
}


$secondary: #e9b5d2;
$black: #1b1b1d;
$grey-90: #575b66;
$grey-70: #828999;
$grey-50: #b9bfce;
$grey-30: #dee0e9;
$white: #ffffff;
$berry: #951556;
$deep-purple: #35013f;
$size-border-radius: 138px




