.grid-container {
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 7.5fr 1fr;
  grid-gap: 0px 0%;
  gap: 0px 0%;
  grid-template-areas:
        "header"
        "body"
        "footer";


  @media only screen and (width: 280px) {
    height: 100vh;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows:  1fr 7.5fr 1fr;
    grid-gap: 0px 0%;
    gap: 0px 0%;
    grid-template-areas:
        "header"
        "body"
        "footer";
  }
  @media only screen and (min-width: 360px) and (max-width: 767px) {
    height: 93vh;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows:  min-content 7.5fr 1fr;
    grid-gap: 0px 0%;
    gap: 0px 0%;
    grid-template-areas:
        "header"
        "body"
        "footer";
  }

  .header {
    grid-area: header;
    display: flex;
    justify-content: center;
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
      grid-area: header;
      display: flex;
      justify-content: center;

    }
    @media only screen and (width: 360px) and (height: 640px) {
      grid-area: header;
      display: flex;
      justify-content: center;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
      img {
        height: 71px;
      }
    }
  }

  .body {
    grid-area: body;
    overflow-y: scroll;
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
      height: auto;
    }

  }

  .footer {
    grid-area: footer;
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    grid-template-rows:  min-content 7.5fr 1fr;
    height: 87vh;
  }
}

.grid-container-browser {
  @media only screen and (min-width: 360px) and (max-width: 767px) {
    height: 87vh;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 7.5fr 1fr;
    grid-gap: 0px 0%;
    gap: 0px 0%;
    grid-template-areas:
        "header"
        "body"
        "footer";
  }
}

.grid-container-ios {
  height: 88vh !important;
  grid-template-rows:  min-content 7.5fr 1fr;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 0px 0%;
  gap: 0px 0%;
  grid-template-areas:
        "header"
        "body"
        "footer";

  img {
    height: 71px;
  }

}


